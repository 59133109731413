<template>
    <div>
        <div class="modal fade" id="vehicleModal" tabindex="-1" aria-labelledby="vehicleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="vehicleModalLabel" class="font-weight-bold">{{ModalTitle}}{{VehicleCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>

                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Kode Kendaraan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="VehicleCode" v-model="VehicleCode" class="font-weight-bold" />
                                    <label id="errorVehicleCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Tahun Kendaraan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="VehicleYear" type="number" v-model="VehicleYear" class="font-weight-bold" />
                                    <label id="errorVehicleYear" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Keterangan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <textarea class="form-control" rows="2" v-model="Notes"></textarea>
                                    <label id="errorNotes" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vehicleService from '../Script/VehicleService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'VehicleForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            VehicleId:'',
            VehicleCode: '',
            VehicleYear: '',
            Notes: '',
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Vehicle: ';       
            this.VehicleId = null;
            this.VehicleCode = null;
            this.Notes = null;
            this.VehicleYear = null;

            this.SaveType = 'Add';
            window.$('#vehicleModal').modal('show');
        },
        editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Vehicle: '       
            this.VehicleId = data.vehicle_id;
            this.VehicleCode = data.license_plate;
            this.Notes = data.notes;
            this.VehicleYear = data.vehicle_year;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Vehicle: ';
            }

            window.$('#vehicleModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.VehicleCode == '' || this.VehicleCode == null){
                this.errorShow('errorVehicleCode');
            }
            if(this.VehicleYear == null || this.VehicleYear.toString() == ''){
                this.errorShow('errorVehicleYear');
            }
            if(this.Notes == '' || this.Notes == null){
                this.errorShow('errorNotes');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                const vehicleData = {
                    vehicle_year: this.VehicleYear,
                    notes: this.Notes,
                    license_plate: this.VehicleCode,
                };
                this.$loading(true);
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : vehicleData
                    }

                    vehicleService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#vehicleModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.VehicleId,
                        data : vehicleData
                    }

                    vehicleService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#vehicleModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
